/* styles.css */

html, body, #root, .app-container {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.content-container {
  flex: 1;  /* This makes the content area grow to fill available space */
  padding: 40px 0;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}


